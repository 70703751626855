import { Box, Button, Card, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import { products } from "../../data/products";
import roseGoldCosmetics from '../../assets/roseGoldCosmetics.jpg';
import archiveBodyOil from "../../assets/archiveBodyOil.jpg"
import { HashLink as Link } from 'react-router-hash-link';

const  imageMap: { [key: string]: string } = {
    'roseGoldCosmetics.jpg': roseGoldCosmetics,
    'archiveBodyOil.jpg': archiveBodyOil,
};
  
export function MobileProductView() {
  const mobileProducts = products.slice(5, 7);
  
    return (
      <Box sx={{ padding: 2, margin: "2vh auto" }}>
        <Grid container spacing={2}>
          {mobileProducts.map((product) => (
            <Grid item xs={6} key={product.id}>
              <Card sx={{ backgroundColor: '#d9d9d9', height: "30vh" }}>
                <CardMedia 
                    component="img" height="200" image={imageMap[product.image]}
                    title={product.name}
                    />
                
                <CardContent>
                    <Typography gutterBottom variant="body1" className='productName'>
                      {product.name}
                    </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        <Link to="/product-types#values" style={{ margin: "auto"}}>
          <Button variant="contained" color="primary" sx={{ margin: 2, backgroundColor: "#d18726" }}>
            VIEW MORE
          </Button>
        </Link>
      </Box>
    );
  }