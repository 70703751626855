// components/Home.js
import Maps from '../../components/maps/Maps';
import { CircleLoader } from 'react-spinners';
import { Store } from 'react-notifications-component';
import { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import "./contactUs.css";

export function ContactUs() {
  const [isLoaded, setIsLoaded] = useState(false)
  const [notificationID, setNotificationID] = useState("")

  function delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  useEffect(() => {
    const getData = async () => {

      setNotificationID(Store.addNotification({
        title: "Wonderful!",
        message: "teodosii@react-notifications-component",
        type: "warning",
        insert: "top",
        container: "top-left",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
          onScreen: true
        }
      }));

      Store.removeNotification(notificationID)
      await delay(1000);
      setIsLoaded(true)

    }
    getData()
  }, [])

  return (
    <>
      {isLoaded ? (
        <>
          <Grid container className="contactPageContainer" spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h1" align="center" className='heading' gutterBottom>
                Contact Us
              </Typography>
            </Grid>

            <Grid item xs={12} md={6} className='contactInfo' mt={4}>
                <Typography variant="h6" gutterBottom>
                  Contact Information
                </Typography>

                <Typography variant="body1" gutterBottom>
                  <strong>Phone:</strong> <span>000 000 0000</span>
                </Typography>

                <Typography variant="body1" gutterBottom>
                  <strong>Company Telephone:</strong> <span>011 000 000</span>
                </Typography>

                <Typography variant="body1" gutterBottom>
                  <strong>Email:</strong> <span>info@example.com</span>
                </Typography>

                <Typography variant="body1">
                  <strong>Contact Person:</strong> <span>John Doe</span>
                </Typography>
            </Grid>

            <Grid item xs={12} md={6} className='addressInfo' mt={4}>
                <Typography variant="h6" gutterBottom>
                  Address Information
                </Typography>

                <Typography variant="body1" gutterBottom>
                  <strong>Street name:</strong><address>Street name</address>
                </Typography>

                <Typography variant="body1" gutterBottom>
                  <strong>Area Name:</strong><address>Area name</address>
                </Typography>

                <Typography variant="body1" gutterBottom>
                  <strong>City/Town:</strong><address>City/Town</address>
                </Typography>

                <Typography variant="body1">
                  <strong>Postal Code</strong><address>Postal Code</address>
                </Typography>
            </Grid>

            <Grid container justifyContent="center">
              <Grid item xs={12} md={10} className='mapsd'>
                  <Maps lng={-38.523} lat={-3.745} heigh={"400px"} width={"100%"} />
              </Grid>
            </Grid>

          </Grid>
        </>
      ) : (
        <div style={{ width: '100%', height: '70vh', alignContent: 'center', textAlign: 'center', justifyContent: 'center', display: 'flex' }}>
        <div style={{ marginTop: '25vh' }}> <CircleLoader color="#d18726" size={150}/> </div>
      </div>
      )}
    </>
  );
}
