import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import patchuoliOil from "../../assets/patchouliHairOIl.jpg";
import hairConditioner2 from '../../assets/images/hairConditioner2.svg';
import shampoo from '../../assets/images/shampoo.svg';
import product6 from '../../assets/lipBalm.jpg';
import product7 from '../../assets/stopItLotion.jpg';
import cantuHairConditioner from '../../assets/cantuHairConditioner.jpg';
import bbEyeShadow from "../../assets/bbEyeShadow.jpg";
import './carousel.css';
import { Button } from '@mui/material';

const products = [
  {
    label: 'Patchouli Hair Oil',
    imgPath: patchuoliOil,
  },
  {
    label: 'Function of beauty hair conditioner',
    imgPath: hairConditioner2,
  },
  {
    label: 'Hairgro Shampoo',
    imgPath: shampoo,
  },
  {
    label: 'Cantu Hair Conditioner',
    imgPath: cantuHairConditioner,
  },
  {
    label: 'BB Eye Shadow',
    imgPath: bbEyeShadow,
  },
  {
    label: 'BB Lip Balm',
    imgPath: product6,
  },
  {
    label: 'Stop It Lotion',
    imgPath: product7,
  },
];

function MobileProductView() {
  const mobileProducts = products.slice(0, 2);

  return (
    <Box sx={{ padding: 2 }}>
      <Button variant="contained" color="primary" sx={{ marginBottom: 2 }}>
        Go to Products Page
      </Button>
      
      <Grid container spacing={2}>
        {mobileProducts.map((product) => (
          <Grid item xs={6} key={product.label}>
            <Card sx={{ backgroundColor: '#d9d9d9' }}>
              <CardMedia component="img" height="200" image={product.imgPath} alt={product.label} />
              <CardContent>
                <Typography variant="h6">{product.label}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}


function NetflixCarousel() {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const itemsPerPage = 3;
  const maxSteps = Math.ceil(products.length / itemsPerPage);

  const handleNext = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % maxSteps);
  };

  const handleBack = () => {
    setActiveIndex((prevIndex) => (prevIndex - 1 + maxSteps) % maxSteps);
  };

  return (
    <Box sx={{ maxWidth: 1300, margin: '10vh auto', position: 'relative' }}>
      <IconButton
        onClick={handleBack}
        sx={{
          position: 'absolute',
          top: '50%',
          left: 0,
          transform: 'translateY(-25%)',
          zIndex: 1,
          color: '#a86d1e',
        }}
      >
        <KeyboardArrowLeft sx={{ backgroundColor: "#d18726", borderRadius: "50%", color: "white", fontSize: "2rem"}} />
      </IconButton>

      <Grid container spacing={2} sx={{ overflow: 'hidden' }}>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            transform: `translateX(-${activeIndex * (100 / maxSteps)}%)`,
            transition: 'transform 0.5s ease',
            width: `${maxSteps * 100}%`,
          }}
        >
          {products.map((product) => (
            <Card
              key={product.label}
              sx={{
                backgroundColor: '#d9d9d9',
                minWidth: 280,
                flexShrink: 0,
                marginRight: '20px',
              }}
            >
              <CardMedia component="img" height="200" image={product.imgPath} alt={product.label} />
              <CardContent>
                <Typography variant="h6">{product.label}</Typography>
              </CardContent>
            </Card>
          ))}
        </Grid>
      </Grid>

      <IconButton
        onClick={handleNext}
        sx={{
          position: 'absolute',
          top: '50%',
          right: 0,
          transform: 'translateY(-50%)',
          zIndex: 1,
          color: '#a86d1e',
        }}
      >
        <KeyboardArrowRight sx={{ backgroundColor: "#d18726", borderRadius: "50%", color: "white", fontSize: "2rem"}} />
      </IconButton>
    </Box>
  );
}

export default NetflixCarousel;



