import { useEffect } from "react";
import './Login.css';

import { Authenticator, useAuthenticator, View } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import { useNavigate, useLocation } from 'react-router';

export function Login() {
  const { route } = useAuthenticator((context) => [context.route]);
  const location = useLocation();
  const navigate = useNavigate();

  const theme = {
    name: 'whatever',
    tokens: {
      colors: {
        background: {
          primary: { value: 'hotpink'}
        }
      }
    }
  }

  let from = location.state?.from?.pathname || '/';
  
  useEffect(() => {
    if (route === 'authenticated') {
      navigate(from, { replace: true });
    }
  }, [route, navigate, from]);
  
  return (
    <View className="auth-wrapper" >
      <Authenticator className="formds"></Authenticator>
    </View>
  );
}