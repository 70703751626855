
import "./Footer.css";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { TextField } from "@aws-amplify/ui-react";
import Button from '@mui/material/Button';
import { Link, Typography } from "@mui/material";
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import XIcon from '@mui/icons-material/X';
import InstagramIcon from '@mui/icons-material/Instagram';
import { useState } from 'react';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: "#1a1a1a",
    ...theme.typography.body2,
    padding: theme.spacing(4),
    textAlign: 'center',
    color: "#dadada",
    boxShadow: "none"
})); 

export function Footer() {
    const [formState, setFormState] = useState({
        first_name: '',
        business_name: '',
        card_number: '',
        month_expiration: '',
        cvc: '',
        description: '',
        phone: '',
        email: '',
        crn: '',
        industry: '',
        website: '',
        currency: 'ZAR',
        street_name: '',
        city_name: '',
        province_name: '',
        zip_code: '',
        country_name: '',
        linked_users_invite: {}
      });
    
      // Regular expression for validating phone numbers (supports various formats)
      const phoneRegex = /^(?:(?:\+|00)([1-9]\d{0,2}))?([-\s./0-9]*\d[-\s./0-9]*)$/;
    
      // Regular expression for validating website URLs (supports HTTP and HTTPS)
      const websiteRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/;
    
      // Email regref
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
      // Event handler for form input changes
      const [errors, setErrors] = useState({
        website: '',
        phone: '',
        email: '',
      });
    
      const handleChange = (event: any) => {
        const { name, value } = event.target;
        let errorMessage = '';
    
        // Perform validation based on the field name
        if (name === 'website') {
          console.log("Checking")
          if (!value.match(websiteRegex)) {
            errorMessage = 'Invalid website URL';
          }
        } else if (name === 'phone') {
          if (!value.match(phoneRegex)) {
            errorMessage = 'Invalid phone number';
          }
        } else if (name === 'email') {
          if (!value.match(emailRegex)) {
            errorMessage = 'Invalid email';
          }
        }
    
        // Update the form state and errors
        setFormState({ ...formState, [name]: value });
        setErrors({ ...errors, [name]: errorMessage });
      };

    return (
            <footer className="footerContainer">
                <Grid container className="footer-container">
                    <Grid item xs={12} md={3}>
                        <Item>
                            <div className="container footer-1">
                                <Typography variant="h2" className="footerTitles">SUPPORT</Typography>
                                
                                <h3><a>info1@blackbutterfly.com</a></h3>
                                <h3><a>info2@blackbutterfly.com</a></h3>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <Item>
                            <div className="container footer-2">
                            <Typography variant="h2" className="footerTitles">TRADING HOURS</Typography>

                            <h3>Monday-Thurdsday: 08:00 - 17:00 </h3>
                            <h3>Friday: 08:00 - 15:00 </h3>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <Item>
                            <div className="container footer-3">
                                <Typography variant="h2" className="footerTitles">SOCIALS</Typography>

                                <Grid item className="footerLinks">
                                    <Link  href="https://www.facebook.com/" color="#fff">
                                        <h3>
                                          <a><FacebookIcon /></a>
                                        </h3>
                                      </Link>
                                   
                                      <Link href="https://wa.me/" color="#fff">
                                        <h3>
                                          <a><WhatsAppIcon /></a>
                                        </h3>
                                      </Link>

                                      <Link href="https://twitter.com/" color="#fff">
                                        <h3>
                                          <a> <XIcon /> </a>
                                        </h3>
                                      </Link>

                                      <Link  href="https://www.instagram.com/" color="#fff">
                                        <h3>
                                          <a> <InstagramIcon /> </a>
                                        </h3>
                                      </Link>

                                </Grid>
                            </div>
                        </Item>
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <Item>
                            <div className="container footer-4">
                                <Typography variant="h2" className="footerTitles">DROP US A NOTE!</Typography>

                                <TextField
                                    required
                                    id="outlined-required"
                                    label={undefined}                           
                                />

                                <Button variant="contained" className="sendButton">Send</Button>
                            </div> 
                        </Item>
                    </Grid>
                </Grid>
            </footer>
    );
}
