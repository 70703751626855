// components/Home.tsx
import { Link, useParams } from 'react-router-dom';
import { Store } from 'react-notifications-component';
import { useEffect, useState } from 'react';
import { useCart } from '../../context/cartContext';
import { Box, Button, Grid, Typography, Select, MenuItem, FormControl, InputLabel, Drawer, List, ListItem } from '@mui/material';
import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { products } from '../../data/products';
import { CircleLoader } from 'react-spinners';
import "./products.css";

// Images
import product1 from '../../assets/alexanderBodyOil.jpg';
import product2 from '../../assets/bodyOil2.jpg';
import product3 from '../../assets/bodyOil3.jpg';
import product4 from '../../assets/archiveBodyOil.jpg';
import product5 from '../../assets/bodyOil7.jpg';
import product6 from '../../assets/ladorMorrocanOil.jpg';

import product7 from '../../assets/roseGoldCosmetics.jpg';
import product8 from '../../assets/roseGoldCosmetics2.jpg';
import product9 from '../../assets/naturalLotion.jpg';
import product10 from '../../assets/birgithLotion.jpg';
import product11 from '../../assets/blackButterflyLotion.jpg';
import product12 from '../../assets/ordinaryLotionRange.jpg';

import product13 from '../../assets/TFEyeCare.jpg';
import product14 from '../../assets/eyeShadow.jpg';
import product15 from '../../assets/fuxtEyeCare.jpg';
import product16 from '../../assets/lamerEyeRange.jpg';
import product17 from '../../assets/narsEyeRange.jpg';
import product18 from '../../assets/hoverEyeRange.jpg';

import product19 from '../../assets/arthurPereiraHairRange.jpg';
import product20 from '../../assets/apothecaryHairShampoo.jpg';
import product21 from '../../assets/panteneHairConditioner.jpg';
import product22 from '../../assets/luminHairRange.jpg';
import product23 from '../../assets/frontRowhairConditioner.jpg';
import product24 from '../../assets/curologyHairspray.jpg';

const imageMap: { [key: string]: string } = {
  'alexanderBodyOil.jpg': product1,
  'bodyOil2.jpg': product2,
  'bodyOil3.jpg': product3,
  'archiveBodyOil.jpg': product4,
  'bodyOil7.jpg': product5,
  'bodyOil6.jpg': product6,

  'roseGoldCosmetics.jpg': product7,
  'roseGoldCosmetics2.jpg': product8,
  'naturalLotion.jpg': product9,
  'birgithLotion.jpg': product10,
  'blackButterflyLotion.jpg': product11,
  'ordinaryLotionRange.jpg': product12,

  'TFEyeCare.jpg': product13,
  'eyeShadow.jpg': product14,
  'fuxtEyeCare.jpg': product15,
  'lamerEyeRange.jpg': product16,
  'narsEyeRange.jpg': product17,
  'hoverEyeRange.jpg': product18,

  'arthurPereiraHairRange.jpg': product19,
  'apothecaryHairShampoo.jpg': product20,
  'panteneHairConditioner.jpg': product21,
  'luminHairRange.jpg': product22,
  'frontRowhairConditioner.jpg': product23,
  'curologyHairspray.jpg': product24,
};

export function Products() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [notificationID, setNotificationID] = useState("");
  const [filteredProducts, setFilteredProducts] = useState(products);
  const [selectedBrand, setSelectedBrand] = useState('');
  const [selectedSize, setSelectedSize] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [sortOption, setSortOption] = useState('');
  const [drawerOpen, setDrawerOpen] = useState(false);

  // Temporary states for filters
  const [tempBrand, setTempBrand] = useState('');
  const [tempSize, setTempSize] = useState('');
  const [tempCategory, setTempCategory] = useState('');
  const [tempSortOption, setTempSortOption] = useState('');

  const params = useParams();
  const { addToCart } = useCart();

  function delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  useEffect(() => {
    const getData = async () => {
      setNotificationID(Store.addNotification({
        title: "Wonderful!",
        message: "teodosii@react-notifications-component",
        type: "warning",
        insert: "top",
        container: "top-left",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
          onScreen: true
        }
      }));

      Store.removeNotification(notificationID);
      await delay(1000);
      setIsLoaded(true);
    };
    getData();
  }, []);

  useEffect(() => {
    filterAndSortProducts();
  }, [selectedBrand, selectedSize, selectedCategory, sortOption]);

  const filterAndSortProducts = () => {
    let filtered = products;

    if (selectedBrand) {
      filtered = filtered.filter(product => product.brand === selectedBrand);
    }

    if (selectedSize) {
      filtered = filtered.filter(product => product.size === selectedSize);
    }

    if (selectedCategory) {
      filtered = filtered.filter(product => product.category === selectedCategory);
    }

    if (sortOption) {
      if (sortOption === 'name-asc') {
        filtered.sort((a, b) => a.name.localeCompare(b.name));
      } else if (sortOption === 'price-asc') {
        filtered.sort((a, b) => a.price - b.price);
      } else if (sortOption === 'price-desc') {
        filtered.sort((a, b) => b.price - a.price);
      }
    }

    setFilteredProducts(filtered);
  };

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
      return;
    }

    setDrawerOpen(open);
  };

  // Apply filters
  const applyFilters = () => {
    setSelectedBrand(tempBrand);
    setSelectedSize(tempSize);
    setSelectedCategory(tempCategory);
    setSortOption(tempSortOption);
    setDrawerOpen(false);
  };

  // Reset filters
  const resetFilters = () => {
    setTempBrand('');
    setTempSize('');
    setTempCategory('');
    setTempSortOption('');
  };

  // Close the drawer without applying filters 
  const closeDrawer = () => {
    if (!tempBrand && !tempSize && !tempCategory && !tempSortOption) {
      setDrawerOpen(false);
    }
  };

  const drawer = (
    <List sx={{ width: 300, height: "100%"}}>
      <ListItem>
        <FormControl fullWidth>
          <InputLabel>Brand</InputLabel>
          <Select value={tempBrand} onChange={(e) => setTempBrand(e.target.value)}>
            <MenuItem value="">All</MenuItem>
            {Array.from(new Set(products.map(product => product.brand))).map((brand) => (
              <MenuItem key={brand} value={brand}>{brand}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </ListItem>

      <ListItem>
        <FormControl fullWidth>
          <InputLabel>Size</InputLabel>
          <Select value={tempSize} onChange={(e) => setTempSize(e.target.value)}>
            <MenuItem value="">All</MenuItem>
            {Array.from(new Set(products.map(product => product.size))).map((size) => (
              <MenuItem key={size} value={size}>{size}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </ListItem>

      <ListItem>
        <FormControl fullWidth>
          <InputLabel>Category</InputLabel>
          <Select value={tempCategory} onChange={(e) => setTempCategory(e.target.value)}>
            <MenuItem value="">All</MenuItem>
            {Array.from(new Set(products.map(product => product.category))).map((category) => (
              <MenuItem key={category} value={category}>{category}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </ListItem>

      <ListItem>
        <FormControl fullWidth>
          <InputLabel>Sort By</InputLabel>
          <Select value={tempSortOption} onChange={(e) => setTempSortOption(e.target.value)}>
            <MenuItem value="">None</MenuItem>
            <MenuItem value="name-asc">A-Z</MenuItem>
            <MenuItem value="price-asc">Price Low to High</MenuItem>
            <MenuItem value="price-desc">Price High to Low</MenuItem>
          </Select>
        </FormControl>
      </ListItem>

      <ListItem>
        <Button variant="contained" onClick={applyFilters} sx={{ backgroundColor: "#806044", margin: "auto", "&: hover": {
          backgroundColor: "#d18726", fontWeight: 600
        }}}>Apply</Button>
      </ListItem>

      <ListItem>
        <Button variant="outlined" onClick={resetFilters} sx={{ borderColor: "#806044", margin: "auto", color: "#806044", fontWeight: 600, "&: hover": {
          border: "2px solid #d18726", color: "#fff"
        }}}>Reset</Button>
      </ListItem>

      <ListItem>
        <Button variant="text" onClick={closeDrawer} sx={{ color: "red", margin: "auto", "&: hover": { backgroundColor: "salmon", color: "white"}}}>Close</Button>
      </ListItem>
    </List>
  );

  return (
    <>
      {isLoaded ? (
        <>
          <Grid container className='productsPageContainer'>
            <Typography variant="h1" gutterBottom className='heading' id="ror">Products</Typography>

        
            <Button
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer(true)}
              style={{ width: '140px' }}
            >
              Filters
            </Button>
            
            <Drawer
              anchor="left"
              open={drawerOpen}
              onClose={toggleDrawer(false)}
            >
              {drawer}
            </Drawer>

            <Grid container className="productsContainer">
              {filteredProducts.map(product => (
                <Box key={product.id} m={4} className='productsBox'>
                  <Card className='productCard' sx={{ maxWidth: { xs: 245, lg: 345 }, backgroundColor: "#d9d8d6", boxShadow: "none", margin: "auto" }}>
                    <CardMedia
                      className='image'
                      image={imageMap[product.image]}
                      title={product.name}
                    />
                    <Link id='dds' to="/product-details" className='custom-link' style={{ width: "200px" }}>
                      <CardContent>
                        <Typography gutterBottom variant="body1" className='productName'>
                          {product.name}
                        </Typography>
                        <Typography variant='body2' className='price'>R {product.price.toFixed(2)}</Typography>
                      </CardContent>
                    </Link>
                    
                    <CardActions>
                      <Button variant='contained' className='addToCart' onClick={() => addToCart({ ...product, quantity: 1 })} size="small">
                        Add To Cart
                      </Button>
                    </CardActions>
                  </Card>
                </Box>
              ))}
            </Grid>
          </Grid>
        </>
      ) : (
        <div style={{ width: '100%', height: '70vh', alignContent: 'center', textAlign: 'center', justifyContent: 'center', display: 'flex' }}>
          <div style={{ marginTop: '25vh' }}> <CircleLoader color="#d18726" size={150} /> </div>
        </div>
      )}
    </>
  );
}
