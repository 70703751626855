import { CircleLoader, RingLoader } from 'react-spinners';
import { Store } from 'react-notifications-component';
import { useEffect, useState } from 'react';
import { Button, Grid, TextField, Typography } from '@mui/material';
import * as React from 'react';
import { styled } from "@mui/material/styles";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import "./paymentInfo.css";

const TextfieldLabels = styled(Typography)({
    color: "#1a1a1a",
    fontSize: "1.2rem",
    fontStyle: "normal",
    fontWeight: "600",
    textAlign: "left",
  })

export function PaymentInfo() {
  const [isLoaded, setIsLoaded] = useState(false)
  const [notificationID, setNotificationID] = useState("")

  const [alignment, setAlignment] = React.useState<string | null>('left');

  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string | null,
  ) => {
    setAlignment(newAlignment);
  };

  const [formState, setFormState] = useState({
    first_name: '',
    business_name: '',
    card_number: '',
    month_expiration: '',
    cvc: '',
    description: '',
    phone: '',
    email: '',
    crn: '',
    industry: '',
    website: '',
    currency: 'ZAR',
    street_name: '',
    city_name: '',
    province_name: '',
    zip_code: '',
    country_name: '',
    linked_users_invite: {}
  });

  // Regular expression for validating phone numbers (supports various formats)
  const phoneRegex = /^(?:(?:\+|00)([1-9]\d{0,2}))?([-\s./0-9]*\d[-\s./0-9]*)$/;

  // Regular expression for validating website URLs (supports HTTP and HTTPS)
  const websiteRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/;

  // Email regref
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Event handler for form input changes
  const [errors, setErrors] = useState({
    website: '',
    phone: '',
    email: '',
  });

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    let errorMessage = '';

    // Perform validation based on the field name
    if (name === 'website') {
      console.log("Checking")
      if (!value.match(websiteRegex)) {
        errorMessage = 'Invalid website URL';
      }
    } else if (name === 'phone') {
      if (!value.match(phoneRegex)) {
        errorMessage = 'Invalid phone number';
      }
    } else if (name === 'email') {
      if (!value.match(emailRegex)) {
        errorMessage = 'Invalid email';
      }
    }

    // Update the form state and errors
    setFormState({ ...formState, [name]: value });
    setErrors({ ...errors, [name]: errorMessage });
  };

  function delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  useEffect(() => {
    const getData = async () => {

      setNotificationID(Store.addNotification({
        title: "Wonderful!",
        message: "teodosii@react-notifications-component",
        type: "warning",
        insert: "top",
        container: "top-left",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
          onScreen: true
        }
      }));

      Store.removeNotification(notificationID)
      await delay(1000);
      setIsLoaded(true)

    }
    getData()
  }, [])

  return (
    <>
      {isLoaded ? (
        <>
        <Grid container className='parentContainer'>
          <Grid container className='paymentInfoContainer'>
            <Typography variant="h1" className='heading'>Payment details</Typography>

            <Grid container className='paymentMethod'>
              <Typography variant="h2" className='paymentText'>Payment method</Typography>

              <ToggleButtonGroup
                    value={alignment}
                    exclusive
                    onChange={handleAlignment}
                    aria-label="text alignment"
                    className='paymentButton'
                    sx={{ margin: "4vw"}}
                >   
                    <ToggleButton value="center">
                        Debit / Credit Card
                    </ToggleButton>

                    <ToggleButton value="left">
                        Flexi pay
                    </ToggleButton>
              </ToggleButtonGroup>
            </Grid> 

            <Grid container direction="column" className='cardDetails'>
                    <Grid item xs={10} sm={4}>
                        <TextfieldLabels id="contact-form" mt={3} mb={1}>Name on card</TextfieldLabels>

                        <TextField
                          id="first_name"
                          label="John Doe"
                          type="text"
                          name="first_name"
                          value={formState.first_name}
                          onChange={handleChange}
                          fullWidth
                          margin="normal"
                          required
                          variant="outlined" 
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    border: '1px solid #1a1a1a',
                                },
                                '&:hover fieldset': {
                                  border: '1px solid #d18726', 
                                },
                                '&.Mui-focused fieldset': {
                                  border: '1px solid #d18726'
                                },
                                '& .MuiInputLabel-root': {
                                  color: '#1a1a1a', 
                                },
                                '& .MuiInputLabel-root.Mui-focused': {
                                  color: '#d18726', 
                                }
                              }
                            }} 
                        />


                        <TextfieldLabels id="contact-form" mt={3} mb={1}>Card details</TextfieldLabels>


                        <TextField
                          id="card_number"
                          label="XXXX XXXX XXXX XXXX" 
                          type="text"
                          name="card_number"
                          value={formState.card_number}
                          onChange={handleChange}
                          fullWidth
                          margin="normal"
                          required
                          variant="outlined" 
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    border: '1px solid #1a1a1a',
                                },
                                '&:hover fieldset': {
                                  border: '1px solid #d18726', 
                                },
                                '&.Mui-focused fieldset': {
                                  border: '1px solid #d18726'
                                },
                                '& .MuiInputLabel-root': {
                                  color: '#1a1a1a', 
                                },
                                '& .MuiInputLabel-root.Mui-focused': {
                                  color: '#d18726', 
                                }
                              }
                            }} 
                        />

                    </Grid>
                                
                    <Grid item container spacing={1} mt={2} justifyContent={"flex-end"}>
                        <Grid item xs={12} lg={3}>
                            
                        <TextField
                          id="month_expiration"
                          label="MM/YY" 
                          type="text"
                          name="month_expiration"
                          value={formState.month_expiration}
                          onChange={handleChange}
                          fullWidth
                          required
                          variant="outlined" 
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    border: '1px solid #1a1a1a',
                                },
                                '&:hover fieldset': {
                                  border: '1px solid #d18726', 
                                },
                                '&.Mui-focused fieldset': {
                                  border: '1px solid #d18726'
                                },
                                '& .MuiInputLabel-root': {
                                  color: '#1a1a1a', 
                                },
                                '& .MuiInputLabel-root.Mui-focused': {
                                  color: '#d18726', 
                                }
                              }
                            }} 
                        />

                        </Grid>

                        <Grid item xs={12} lg={2}>
                          <TextField
                            id="cvc"
                            label="CVC" 
                            type="text"
                            name="cvc"
                            value={formState.cvc}
                            onChange={handleChange}
                            fullWidth
                            required
                            variant="outlined" 
                              sx={{
                                '& .MuiOutlinedInput-root': {
                                  '& fieldset': {
                                      border: '1px solid #1a1a1a',
                                  },
                                  '&:hover fieldset': {
                                    border: '1px solid #d18726', 
                                  },
                                  '&.Mui-focused fieldset': {
                                    border: '1px solid #d18726'
                                  },
                                  '& .MuiInputLabel-root': {
                                    color: '#1a1a1a', 
                                  },
                                  '& .MuiInputLabel-root.Mui-focused': {
                                    color: '#d18726', 
                                  }
                                }
                              }} 
                          />

                        </Grid>
                    </Grid>                                 
            </Grid>

            <Grid container direction="column" className='cardDetails'>
                    <Grid item xs={10} lg={4}>
                        <TextfieldLabels id="contact-form" mt={3} mb={1}>Billing address</TextfieldLabels>

                        <TextField
                          id="street_name"
                          label="Street Name" 
                          type="text"
                          name="street_name"
                          value={formState.street_name}
                          onChange={handleChange}
                          fullWidth
                          margin='normal'
                          required
                          variant="outlined" 
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    border: '1px solid #1a1a1a',
                                },
                                '&:hover fieldset': {
                                  border: '1px solid #d18726', 
                                },
                                '&.Mui-focused fieldset': {
                                  border: '1px solid #d18726'
                                },
                                '& .MuiInputLabel-root': {
                                  color: '#1a1a1a', 
                                },
                                '& .MuiInputLabel-root.Mui-focused': {
                                  color: '#d18726', 
                                }
                              }
                            }} 
                        />

                    </Grid>
                                
                    <Grid item container spacing={1} mt={2}>
                        <Grid item xs={12} lg={6}>
                          <TextField
                            id="zip_code"
                            label="ZIP code" 
                            type="text"
                            name="zip_code"
                            value={formState.zip_code}
                            onChange={handleChange}
                            fullWidth
                            margin='normal'
                            required
                            variant="outlined" 
                              sx={{
                                '& .MuiOutlinedInput-root': {
                                  '& fieldset': {
                                      border: '1px solid #1a1a1a',
                                  },
                                  '&:hover fieldset': {
                                    border: '1px solid #d18726', 
                                  },
                                  '&.Mui-focused fieldset': {
                                    border: '1px solid #d18726'
                                  },
                                  '& .MuiInputLabel-root': {
                                    color: '#1a1a1a', 
                                  },
                                  '& .MuiInputLabel-root.Mui-focused': {
                                    color: '#d18726', 
                                  }
                                }
                              }} 
                          />

                        </Grid>

                        <Grid item xs={12} lg={6}>

                          <TextField
                              id="city_name"
                              label="City Name" 
                              type="text"
                              name="city_name"
                              value={formState.city_name}
                              onChange={handleChange}
                              fullWidth
                              margin='normal'
                              required
                              variant="outlined" 
                                sx={{
                                  '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        border: '1px solid #1a1a1a',
                                    },
                                    '&:hover fieldset': {
                                      border: '1px solid #d18726', 
                                    },
                                    '&.Mui-focused fieldset': {
                                      border: '1px solid #d18726'
                                    },
                                    '& .MuiInputLabel-root': {
                                      color: '#1a1a1a', 
                                    },
                                    '& .MuiInputLabel-root.Mui-focused': {
                                      color: '#d18726', 
                                    }
                                  }
                                }} 
                          />

                        </Grid>

                        <Grid item xs={12} lg={5}>
                          <TextField
                                id="city_name"
                                label="Country" 
                                type="text"
                                name="country_name"
                                value={formState.country_name}
                                onChange={handleChange}
                                fullWidth
                                margin='normal'
                                required
                                variant="outlined" 
                                  sx={{
                                    '& .MuiOutlinedInput-root': {
                                      '& fieldset': {
                                          border: '1px solid #1a1a1a',
                                      },
                                      '&:hover fieldset': {
                                        border: '1px solid #d18726', 
                                      },
                                      '&.Mui-focused fieldset': {
                                        border: '1px solid #d18726'
                                      },
                                      '& .MuiInputLabel-root': {
                                        color: '#1a1a1a', 
                                      },
                                      '& .MuiInputLabel-root.Mui-focused': {
                                        color: '#d18726', 
                                      }
                                    }
                                  }} 
                          />

                        </Grid>
                    </Grid>                                 
            </Grid>

            <Grid container>
              <Button variant='contained' className='orderButton'>Place order</Button>
            </Grid>
            
          </Grid>
        </Grid>
      
        </>
      ) : (
        <div style={{ width: '100%', height: '70vh', alignContent: 'center', textAlign: 'center', justifyContent: 'center', display: 'flex' }}>
          <div style={{ marginTop: '25vh' }}> <CircleLoader color="#d18726" size={150}/> </div>
        </div>
      )}
    </>


  );
}
