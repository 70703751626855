import { CircleLoader } from 'react-spinners';
import { Store } from 'react-notifications-component';
import { useEffect, useState } from 'react';
import { products } from '../../data/products';
import { useCart } from '../../context/cartContext';
import { Box, Button, Grid, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import './productTypes.css';
import { CardActionArea } from '@mui/material';
import iconImage1 from "../../assets/iconImage1.svg";
import iconImage2 from "../../assets/iconImage2.svg";
import iconImage3 from "../../assets/iconImage3.svg";
import productSectionImage1 from "../../assets/productSectionImage1.png";
import productSectionImage2 from "../../assets/productSectionImage2.png";
import productSectionImage3 from "../../assets/productSectionImage3.png";
import productSectionImage4 from "../../assets/productSectionImage4.png";
import  productsImage from "../../assets/productsImage.jpg"
import { HashLink as Link } from 'react-router-hash-link';

//Images
import product1 from '../../assets/alexanderBodyOil.jpg';
import product2 from '../../assets/bodyOil2.jpg';
import product3 from '../../assets/bodyOil3.jpg';
import product5 from '../../assets/bodyOil7.jpg'

const  imageMap: { [key: string]: string } = {
  'alexanderBodyOil.jpg': product1,
  'bodyOil2.jpg': product2,
  'bodyOil3.jpg': product3,
  'bodyOil7.jpg': product5,
};

export function ProductTypes() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [notificationID, setNotificationID] = useState("");
  const { addToCart } = useCart();

  const shortProducts = products.slice(0, 4);

  function delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  useEffect(() => {
    const getData = async () => {
      setNotificationID(Store.addNotification({
        title: "Wonderful!",
        message: "teodosii@react-notifications-component",
        type: "warning",
        insert: "top",
        container: "top-left",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
          onScreen: true
        }
      }));

      Store.removeNotification(notificationID);
      await delay(1000);
      setIsLoaded(true);
    };
    getData();
  }, []);

  return (
    <>
      {isLoaded ? (
        <Grid container className='homeContainer'>
          <Box className="homeImage">
            <img src={productsImage} />
            <div className="text-overlayd" id="values">SHOP</div>
          </Box>

          <Grid container>
            <Grid container justifyContent="center"  sx={{ display: "flex", flexDirection: {xs: "column", md: "row"}, margin: "auto" }}>
              <Grid item xs={10} md={7} lg={2} className='iconContainer'>
                <img src={iconImage1} className='sss' />
                <Typography variant='body1' className='iconText'>Cruelty - Free</Typography>
              </Grid>

              <Grid item xs={10} md={7} lg={2} className='iconContainer'>
                <img src={iconImage2} className='sss' />
                <Typography variant='body1' className='iconText'>Free shipping for purchases over R500 / Nation wide shipping</Typography>
              </Grid>

              <Grid item xs={10} md={7} lg={2} className='iconContainer'> 
                <img src={iconImage3} />
                <Typography variant='body1' className='iconText'>Environ-mentally Friendly</Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid container mt={8}>
            <Grid item xs={10} lg={3}>
                <Card className='card'>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      height="250"
                      image={productSectionImage1}
                      alt="A picture of a black butterfly lotion"
                    />

                    <CardContent sx={{ backgroundColor: "#d9d9d9"}}>
                      <Typography gutterBottom variant="h2" component="div" className='cardTitle'>
                        Cosmetics
                      </Typography>
                    </CardContent>
                    </CardActionArea>
                </Card>  
            </Grid>

            <Grid item xs={10} lg={3}>
                <Card className='card'>
                    <CardActionArea>
                        <CardMedia
                            component="img"
                            height="250"
                            image={productSectionImage2}
                            alt="A picture of a black butterfly lotion"
                        />

                        <CardContent sx={{ backgroundColor: "#d9d9d9"}}>
                            <Typography gutterBottom variant="h2" component="div" className='cardTitle'>
                                Hair
                            </Typography>

                        </CardContent>

                    </CardActionArea>
                </Card>  
            </Grid>

            <Grid item xs={10} lg={3}>
                <Card className='card'>
                    <CardActionArea>
                        <CardMedia
                            component="img"
                            height="250"
                            image={productSectionImage3}
                            alt="A picture of a black butterfly lotion"
                        />

                        <CardContent sx={{ backgroundColor: "#d9d9d9"}}>
                            <Typography gutterBottom variant="h2" component="div" className='cardTitle'>
                              Eye
                            </Typography>

                        </CardContent>

                    </CardActionArea>
                </Card>  
            </Grid>

            <Grid item xs={10} lg={3}>
                <Card className='card'>
                    <CardActionArea>
                        <CardMedia
                            component="img"
                            height="250"
                            image={productSectionImage4}
                            alt="A picture of a black butterfly lotion"
                        />

                        <CardContent sx={{ backgroundColor: "#d9d9d9"}}>
                            <Typography gutterBottom variant="h2" component="div" className='cardTitle'>
                                Body oils
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>  
            </Grid>
              
          </Grid>

          <Grid container>
            <Link to="/products#ror" className='shopAll'>
              <Button variant="contained" color="primary" sx={{ margin: 2, backgroundColor: "#d18726", "&: hover": {
                  backgroundColor: "#806044", fontWeight: 600
                } 
              }}>
                SHOP ALL
              </Button>
            </Link>
          </Grid>

          <Grid container className="productsContainer">
            {shortProducts.map(product => (
              <Box key={product.id} m={4} className='productsBox'>
                <Card className='productCard' sx={{ maxWidth: {xs: 245, lg: 345}, backgroundColor: "#d9d8d6", boxShadow: "none", margin: "auto" }}>
                  <CardMedia
                    className='image'
                    image={imageMap[product.image]}
                    title={product.name}
                  />
                    
                  <Link id='dds' to="/product-details" className='custom-link' style={{width:"200px"}}>
                    <CardContent>
                      <Typography gutterBottom variant="body1" className='productName'>
                        {product.name}
                      </Typography>
                      
                      <Typography variant='body2' className='price'>R {product.price.toFixed(2)}</Typography>
                    </CardContent>
                  </Link>

                  <CardActions>
                    <Button variant='contained' className='addToCart' onClick={() => addToCart({ ...product, quantity: 1 })} size="small">
                      Add To Cart
                    </Button>
                  </CardActions>
                </Card>
              </Box>
            ))}
          </Grid>

        </Grid>
      ) : (
        <div style={{ width: '100%', height: '70vh', alignContent: 'center', textAlign: 'center', justifyContent: 'center', display: 'flex' }}>
        <div style={{ marginTop: '25vh' }}> <CircleLoader color="#d18726" size={150}/> </div>
      </div>
      )}
    </>
  );
}
