// components/Home.js
import { CircleLoader } from 'react-spinners';
import { Store } from 'react-notifications-component';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import "./productDetails.css";
import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './productDetails.css';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { Accordion, AccordionDetails, AccordionSummary, Box, Container, Grid, Typography } from '@mui/material';
import Rating from '@mui/material/Rating';
import { products } from '../../data/products'
import { useCart } from '../../context/cartContext';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

//Images
import product1 from '../../assets/alexanderBodyOil.jpg';
import product2 from '../../assets/bodyOil2.jpg';
import product3 from '../../assets/bodyOil3.jpg';
import product5 from '../../assets/bodyOil7.jpg';
import hairOils from '../../assets/images/hairOilsRange.svg';

const  imageMap: { [key: string]: string } = {
  'alexanderBodyOil.jpg': product1,
  'bodyOil2.jpg': product2,
  'bodyOil3.jpg': product3,
  'bodyOil7.jpg': product5,
};

export function ProductDetails() {

  const [value, setValue] = React.useState<number | null>(4);
  const { addToCart } = useCart();

  const shortProducts = products.slice(0, 2);

  const params = useParams();
  
  const [isLoaded, setIsLoaded] = useState(false)
  const [notificationID, setNotificationID] = useState("")

  function delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  useEffect(() => {
    const getData = async () => {

      setNotificationID(Store.addNotification({
        title: "Wonderful!",
        message: "teodosii@react-notifications-component",
        type: "warning",
        insert: "top",
        container: "top-left",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
          onScreen: true
        }
      }));

      Store.removeNotification(notificationID)
      await delay(1000);
      setIsLoaded(true)

    }
    getData()
  }, [])

  return (
    <>
      {isLoaded ? (
        <>
          <Grid container className='productDetailsContainer'>
            <Grid container>
              <Box sx={{ position: 'absolute', top: {xs: '6rem', lg:'7rem' }, left: {xs: '1rem', lg: '14rem'}, marginBottom: "18vh" }}>
                <Button className='backArrow' startIcon={<ArrowBackIcon />}>
                  <Link to="/products" className="backArrow">
                    Back
                  </Link>
                </Button>
              </Box>

              <Card sx={{ maxWidth: 460, margin: "auto", boxShadow: "none", backgroundColor: "#fdfafa" }}>
                <CardMedia
                  className='cardImg'
                  component="img"
                  alt="Black Butterfly hair oil range"
                  height="180"
                  image={hairOils}
                />

                <CardContent>
                  <Typography gutterBottom variant="h2" className='productName'>
                    Hair Oil 
                  </Typography>

                  <Typography gutterBottom variant="body2" className='price'>
                    R120, 00
                  </Typography>

                  <hr/>

                  <Typography variant="body2" className='productDescription'>
                    Product description (e.g.): Our original hair growth serum uniquely engineered to revive follicles and rejuvenate dullness through cherry blossom extracts and pine needles.
                  </Typography>

                </CardContent>

                <CardActions>
                  <Button variant='contained' className='addToCartt' size="small">ADD TO CART</Button>
                </CardActions>
              </Card>

            </Grid>

            <Grid container>
              <Container className='accordionContainer'>
                <div className='accordion-con'>
                  <Accordion>
                    <AccordionSummary
                      className='accordionHeader'
                      expandIcon={<ExpandMoreIcon className='expandIcon' />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography variant="h6" sx={{ fontSize: { xs: 15, sm: 16, md: 18, lg: 20, xl: 20 } }}>
                        <QuestionMarkIcon className='questionIcon' />
                          BENEFITS
                      </Typography>
                    </AccordionSummary>

                    <AccordionDetails className='accordionBody' >
                      <Typography variant="body1" sx={{ fontSize: { xs: 14, sm: 16, md: 16, lg: 17, xl: 19 } }}>
                        Container solutions offer remarkable versatility. From creating mobile offices to pop-up shops, they provide cost-effective and customizable spaces that adapt to your business needs.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion>
                    <AccordionSummary
                      className='accordionHeader'
                      expandIcon={<ExpandMoreIcon className='expandIcon' />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography variant="h6" className='accordion-header' sx={{ fontSize: { xs: 14, sm: 17, md: 18, lg: 20, xl: 20 } }}>
                        <QuestionMarkIcon className='questionIcon' />
                          APPLICATION
                      </Typography>
                    </AccordionSummary>

                    <AccordionDetails className='accordionBody' >
                      <Typography variant="body1" className='accordion-body' sx={{ fontSize: { xs: 14, sm: 17, md: 17, lg: 19, xl: 19 }, padding: 2 }}>
                        Absolutely. Our container solutions are fully customizable. You can choose colors, branding, and interior layouts that align perfectly with your brand's identity.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion>
                    <AccordionSummary
                      className='accordionHeader'
                      expandIcon={<ExpandMoreIcon className='expandIcon' />}
                      aria-controls="panel3a-content"
                      id="panel3a-header"
                    >
                      <Typography variant="h6" className='accordion-header' sx={{ fontSize: { xs: 14, sm: 17, md: 18, lg: 20, xl: 20 } }}>
                        <QuestionMarkIcon className='questionIcon' />
                          SHIPPING
                      </Typography>
                    </AccordionSummary>

                    <AccordionDetails className='accordionBody' >
                      <Typography variant="body1" className='accordion-body' sx={{ fontSize: { xs: 14, sm: 17, md: 17, lg: 19, xl: 19 }, padding: 2 }}>
                        Yes, container solutions are eco-friendly alternatives. They repurpose shipping containers, reducing waste and supporting sustainable construction practices.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </Container>

            </Grid>

            <Grid container className='ratingContainer'>
              <Box
                sx={{
                  '& > legend': { mt: 2 },
                  textAlign: "left"
                }}
              > 
                <Rating name="read-only" value={value} readOnly />
                <Typography component="legend">AVERAGE RATING</Typography>
                <Typography variant="body1">Based on 50 reviews</Typography>
                <hr></hr>
              </Box>

              <Box
                sx={{
                  '& > legend': { mt: 2 },
                  textAlign: "left"
                }}
              > 
                <Rating name="read-only" value={value} readOnly />
                <Typography component="legend">ANNE - 03 October 2023</Typography>
                <Typography variant="body1">I love black butterfly's serums</Typography>
                <hr></hr>
                
              </Box>

              <Box className="buttonContainer">
                <Button variant='contained' size='large' className='reviewButton'>SHOW ALL REVIEWS</Button>
              </Box>
            </Grid>

            <Grid container className="similarProductsContainer">

              <Grid item className='similarTextContainer'>
                <Typography variant='h2' className='similarProductsText'>Similar products</Typography>
              </Grid>
              
              <Grid item className='showcasedProducts'>
                {shortProducts.map(product => (
                  <Box key={product.id} m={4} sx={{ marginRight: {xs: "0.1vw", sm: "initial"}}}>
                    <Card sx={{ maxWidth: 345, backgroundColor: "#d9d8d6", boxShadow: "none" }}>
                      <CardMedia
                        className='image'
                        image={imageMap[product.image]}
                        title={product.name}
                      />

                      <CardContent>
                        <Typography gutterBottom variant="body1" className='productName'>
                          {product.name}
                        </Typography>

                        <Typography variant='body2' className='price'>R {product.price.toFixed(2)}</Typography>
                      </CardContent>
                      
                      <CardActions>
                        <Button variant='contained' className='addToCart' onClick={() => addToCart({ ...product, quantity: 1 })} size="small">
                          Add To Cart
                        </Button>
                      </CardActions>
                    </Card>
                  </Box>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <div style={{ width: '100%', height: '70vh', alignContent: 'center', textAlign: 'center', justifyContent: 'center', display: 'flex' }}>
          <div style={{ marginTop: '25vh' }}> <CircleLoader color="#d18726" size={150}/> </div>
        </div>
      )}
    </>
  );
}
