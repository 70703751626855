import React from 'react';
import { useCart } from '../../context/cartContext';
import { Button, Typography, Box, Container, Grid, Card, CardContent, CardActions } from '@mui/material';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import DeleteIcon from '@mui/icons-material/Delete';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import './cart.css';

const Cart: React.FC = () => {
    const { items, removeFromCart, clearCart, totalCost } = useCart();

    const getDeliveryStatus = () => {
        if(totalCost >= 1500) {
            return 'You qualify for free delivery.';
        } else {
            return 'The delivery fee is R70.'
        }
    };

    return (
        <Box className='cartContainer'>
            <Typography variant="h1" className="heading">Cart</Typography>

            {items.length === 0 ? (
            <div style={{ width: '100%', height: '50vh', textAlign: 'center', justifyContent: 'center', display: 'flex', flexDirection: "column", alignItems: "center" }}>
                <ProductionQuantityLimitsIcon sx={{ fontSize: "3rem" }}/>
                <Typography variant="body1" sx={{ fontSize: "1.5rem"}}>Your cart is empty.</Typography>

            </div>
            ) : (
                <Container>
                    <Grid container spacing={2}>
                        {items.map(item => (
                            <Grid item xs={12} sm={6} md={4} key={item.id}>
                                <Card sx={{ maxWidth: 345, backgroundColor: "#d9d8d6", boxShadow: "none", borderRadius: "0.5vw", margin: "auto" }}>
                                    <CardContent>
                                        <img
                                            src={require(`../../assets/${item.image}`)}
                                            alt={item.name}
                                            style={{ width: 200, height: 250, borderRadius: '0.5vw' }}
                                        />

                                        <Typography gutterBottom variant="body1" className='productName'>
                                            {item.name}
                                        </Typography>

                                        <Typography variant='body2' className='price'>Quantity: </Typography>

                                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                            <InputLabel id={`select-label-${item.id}`}>{item.quantity}</InputLabel>

                                            <Select
                                                labelId={`select-label-${item.id}`}
                                                id={`select-${item.id}`}
                                                defaultValue={item.quantity}
                                                label={item.quantity}
                                                sx={{
                                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: '#d18726', 
                                                    },
                                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: '#d18726', 
                                                    },
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: '#d18726', 
                                                    },
                                                }}
                                            >
                                                <MenuItem value={1}>One</MenuItem>
                                                <MenuItem value={2}>Two</MenuItem>
                                                <MenuItem value={3}>Three</MenuItem>
                                                <MenuItem value={40}>Other</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <Typography variant='body2' className='price'>Price: R {item.price.toFixed(2)}</Typography>

                                    </CardContent>
                                    
                                    <CardActions>
                                        <Button variant='contained' className='removeButton' onClick={() => removeFromCart(item.id)} size="small">
                                            Remove
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>

                    <Box display="flex" justifyContent="flex-end" mt={2}>
                        <Button variant="contained" className='clearButton' onClick={clearCart}>
                            <DeleteIcon className='deleteIcon' />Clear Cart
                        </Button>
                    </Box>

                    <Grid container spacing={2} mt={2}>
                        <Grid item xs={12}>
                            <Box className="orderSummary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography variant="body1" className='subTotal'> Subtotal: </Typography>
                                <Typography variant="body1" className='subTotalPrice'> R{totalCost.toFixed(2)} </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <Box className="deliveryInfo" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography variant="body1" className='deliveryFee'>
                                    <LocalShippingIcon className="deliveryIcon" /> 
                                    Shipping cost: 
                                </Typography>
                                <Typography variant="body1" className='deliveryFee'> {getDeliveryStatus()} </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <Box className="totalContainer" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography variant="body1" className='total'> Total:  </Typography>
                                <Typography variant="body1" className='totalPrice'> R{totalCost.toFixed(2)} </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={12} display="flex" justifyContent="center" mt={2}>
                            <Button size="large" className="checkout" variant="contained">Checkout</Button>
                        </Grid>
                    </Grid>
                </Container>
            )}
        </Box>
    );
};

export default Cart;
