import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAuthenticator, Button, Heading, View } from '@aws-amplify/ui-react';
import NavBar from './navbar/Navbar';
import { Footer } from './footer/Footer';

export function Layout() {
  return (
    <>
      <NavBar />
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ flex: '1 0 auto', marginTop: '10vh', width: '100%' }}>
          <Outlet />
        </div>
        <Footer />
      </div>
    </>
  );
}