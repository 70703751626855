import { CircleLoader } from 'react-spinners';
import { Store } from 'react-notifications-component';
import { useEffect, useState } from 'react';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import "./accountInfo.css";

export function AccountInfo() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [notificationID, setNotificationID] = useState("");

  const [formState, setFormState] = useState({
    first_name: '',
    last_name: '',
    business_name: '',
    card_number: '',
    month_expiration: '',
    cvc: '',
    description: '',
    phone: '',
    email: '',
    crn: '',
    industry: '',
    website: '',
    currency: 'ZAR',
    street_name: '',
    city_name: '',
    province_name: '',
    zip_code: '',
    country_name: '',
    password: '',
    contact_number: '',
    linked_users_invite: {}
  });

  // Regular expression for validating phone numbers (supports various formats)
  const phoneRegex = /^(?:(?:\+|00)([1-9]\d{0,2}))?([-\s./0-9]*\d[-\s./0-9]*)$/;

  // Regular expression for validating website URLs (supports HTTP and HTTPS)
  const websiteRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/;

  // Email regref
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Event handler for form input changes
  const [errors, setErrors] = useState({
    website: '',
    phone: '',
    email: '',
  });

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    let errorMessage = '';

    // Perform validation based on the field name
    if (name === 'website') {
      console.log("Checking")
      if (!value.match(websiteRegex)) {
        errorMessage = 'Invalid website URL';
      }
    } else if (name === 'phone') {
      if (!value.match(phoneRegex)) {
        errorMessage = 'Invalid phone number';
      }
    } else if (name === 'email') {
      if (!value.match(emailRegex)) {
        errorMessage = 'Invalid email';
      }
    }

    // Update the form state and errors
    setFormState({ ...formState, [name]: value });
    setErrors({ ...errors, [name]: errorMessage });
  };


  function delay(ms:number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  useEffect(() => {
    const getData = async () => {
      setNotificationID(Store.addNotification({
        title: "Wonderful!",
        message: "teodosii@react-notifications-component",
        type: "warning",
        insert: "top",
        container: "top-left",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
          onScreen: true
        }
      }));

      Store.removeNotification(notificationID);
      await delay(1000);
      setIsLoaded(true);
    }
    getData();
  }, []);

  return (
    <>
      {isLoaded ? (
        <Grid container className='accountInfoContainer'>
          <Grid container direction="column" alignItems="center">
            <Typography variant='h1' className='heading' gutterBottom>User Name & Surname</Typography>
            <hr />
          </Grid>

          <Grid container direction="column" alignItems="center" className="accountInfoForm">
            <Typography variant='h2' className='accountInfoTitle' gutterBottom>Account</Typography>

            <Box component="form" noValidate autoComplete="off" mb={4}>
              <TextField
                id="filled-basic" 
                label="Email" 
                variant="filled" 
                type="text"
                name="email"
                value={formState.email}
                onChange={handleChange}
                fullWidth
                margin='normal'
                required
                className='accountInput'
              />

              <TextField
                id="filled-password-input" 
                label="Password" 
                type="password" 
                autoComplete="current-password" 
                variant="filled"
                name="password"
                value={formState.password}
                onChange={handleChange}
                fullWidth
                margin='normal'
                required
                className='accountInput'
              />
            </Box>
          </Grid>

          <Grid container direction="column" alignItems="center" className="accountInfoForm">
            <Typography variant='h2' className='accountInfoTitle' gutterBottom>Personal Information</Typography>

            <Box component="form" noValidate autoComplete="off" mt={4}>
              <TextField
                id="filled-basic" 
                label="Name" 
                variant="filled"
                type="text" 
                name="first_name"
                value={formState.first_name}
                onChange={handleChange}
                fullWidth
                margin='normal'
                required
                className='accountInput'
              />

              <TextField
                id="filled-basic" 
                label="Surname" 
                variant="filled"
                type="text" 
                name="last_name"
                value={formState.last_name}
                onChange={handleChange}
                fullWidth
                margin='normal'
                required
                className='accountInput'
              />

              <TextField
                id="filled-basic" 
                label="Contact Number" 
                variant="filled"
                type="text" 
                name="contact_number"
                value={formState.contact_number}
                onChange={handleChange}
                fullWidth
                margin='normal'
                required
                className='accountInput'
              />


            </Box>
          </Grid>

          <Grid container justifyContent="center" className="buttonContainer">
            <Button variant='outlined' className='accountButton'>DONE</Button>
          </Grid>
        </Grid>
      ) : (
        <div style={{ width: '100%', height: '70vh', alignContent: 'center', textAlign: 'center', justifyContent: 'center', display: 'flex' }}>
        <div style={{ marginTop: '25vh' }}> <CircleLoader color="#d18726" size={150}/> </div>
      </div>
      )}
    </>
  );
}
