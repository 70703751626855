// components/Home.js
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Grid, Typography } from '@mui/material';
import { CircleLoader } from 'react-spinners';
import { useEffect, useState } from 'react';
import { Store } from 'react-notifications-component';
import "./400.css";

export function NotFoundPage() {
  const [isLoaded, setIsLoaded] = useState(false)
  const [notificationID, setNotificationID] = useState("")

  function delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  useEffect(() => {
    const getData = async () => {

      setNotificationID(Store.addNotification({
        title: "Wonderful!",
        message: "teodosii@react-notifications-component",
        type: "warning",
        insert: "top",
        container: "top-left",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
          onScreen: true
        }
      }));

      Store.removeNotification(notificationID)
      await delay(1000);
      setIsLoaded(true)

    }
    getData()
  }, [])

  return (
    <>
      {isLoaded ? (
      <>
        <Grid container sx={{ backgroundColor: "fff", margin: 0}}>
          <Grid container className='contentInfo'>
            <WarningAmberIcon className='amberSign' />
              
              <Typography variant="h1" className="errorHeading notf">
                404
              </Typography>

              <Typography variant="body1" className='notFoundText'>Oops... page not found</Typography>
          </Grid>

        </Grid>
      </>
      ) : (
        <div style={{ width: '100%', height: '70vh', alignContent: 'center', textAlign: 'center', justifyContent: 'center', display: 'flex' }}>
          <div style={{ marginTop: '25vh' }}> <CircleLoader color="#d18726" size={150}/> </div>
        </div>
      )}
    </>
  );
}