import { useCart } from '../../context/cartContext';
import { Box, Button, CardActionArea, Grid, Typography, useMediaQuery } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import homeImage from "../../assets/images/homeImage.svg";
import categoryImage1 from "../../assets/images/categoryImage1.svg";
import categoryImage2 from "../../assets/images/categoryImage2.svg";
import categoryImage3 from "../../assets/images/categoryImage3.svg";
import homeImage2 from "../../assets/images/homeImage2.png";
import teamImage1 from "../../assets/images/teamImage1.svg";
import teamImage2 from "../../assets/images/teamImage2.svg";
import "./home.css"
import NetflixCarousel from '../carousel/carousel';
import { MobileProductView } from '../mobileProductView/mobileProductView';
import { CircleLoader } from 'react-spinners';
import { useEffect, useState } from 'react';
import { Store } from 'react-notifications-component';
import { HashLink as Link } from 'react-router-hash-link';

export function Home() {
    const [isLoaded, setIsLoaded] = useState(false)
    const [notificationID, setNotificationID] = useState("")
    const { addToCart } = useCart();
    const isTabletOrAbove = useMediaQuery('(min-width:1024px)');

    function delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    
    useEffect(() => {
        const getData = async () => {
    
          setNotificationID(Store.addNotification({
            title: "Wonderful!",
            message: "teodosii@react-notifications-component",
            type: "warning",
            insert: "top",
            container: "top-left",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: true
            }
          }));
    
          Store.removeNotification(notificationID)
          await delay(1000);
          setIsLoaded(true)
    
        }
        getData()
    }, [])

    return (
        <>
        {isLoaded ? (
          <>
       <Grid container className='homeContainer'>
            <Box className="homeImage">
                <img src={homeImage} />
                <div className="text-overlay"> TAKE YOUR<br/> <span>locks</span> <br/> TO <span>new</span> <br/> LENGTHS</div>
            </Box>

            <Grid container>
                <Typography variant='body1' className='categoryName' gutterBottom>
                    Choose from a vast selection of quality care
                </Typography>

                <Grid container className='categoryContainer' sx={{ display: "flex", flexDirection: {xs: "column", md: "row"} }}>
                    <Grid item lg={3}>
                        <img src={categoryImage1} />
                    </Grid>

                    <Grid item lg={3}>
                        <img src={categoryImage2} />
                    </Grid>

                    <Grid item lg={3}> 
                        <img src={categoryImage3} />
                    </Grid>
                   
                </Grid>
            </Grid>

            <Grid container>
                <Link to="/products#ror" style={{ margin: "auto"}}>
                    <Button sx={{ margin: "2vh auto", width: "12vw", borderRadius: "0.5vw", fontSize: "1.2rem", height: "8vh", backgroundColor: "#412f26", color: "#806044", "&: hover": {
                  backgroundColor: "#806044", fontWeight: 600, color: "white"
                } 
              }}>
                        SHOP 
                    </Button>
                </Link>
            </Grid>

            <Grid container>
                <Card className='card'>
                    <CardActionArea>
                        <CardMedia
                            component="img"
                            height="200"
                            image={homeImage2}
                            alt="A picture of a black butterfly lotion"
                        />

                        <CardContent sx={{ backgroundColor: "#a86d1e"}}>
                            <Typography gutterBottom variant="h2" component="div" className='cardTitle'>
                                To REVIVE, RENEW, and REVISIT the beauty that lies within.
                            </Typography>

                            <Typography variant="body2" className='cardBody'>
                                We aim to bring you cosmetic and personal care products, formulated in a way that is kind to your skin and your wallet.
                            </Typography>
                        </CardContent>

                    </CardActionArea>
                </Card>   
            </Grid>

            <Grid container>
                <Card className='card2'>
                        <CardContent>
                            <Typography gutterBottom variant="h2" component="div" className='cardTitle2'>
                                A Brand for the people, by regular people
                            </Typography>

                            <Typography variant="body2" className='cardBody2'>
                                Tired of unrealistic beauty standards and unrelatable brands? We've got you covered. 
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia nam quibusdam adipisci molestiae autem beatae maxime eaque commodi veniam saepe voluptates eveniet dolorem, quod doloremque ratione enim consequatur voluptatem assumenda!
                            </Typography>
                        </CardContent>
                </Card>   
            </Grid>

            <Grid container>
                {isTabletOrAbove ? <NetflixCarousel /> : <MobileProductView />}
            </Grid>

           

            <Grid container>
                    <Typography variant='h2' className='teamSectionTitle'>
                        MEET THE TEAM
                    </Typography>

                <Grid container className='teamSection'>
                    <Grid item xs={12} md={5} className='teamMember'>
                        <img src={teamImage1} />
                        
                        <Box className='memberInfo'>
                            <Typography variant='body1'>Name</Typography>
                            <Typography variant='body1'>Position</Typography>
                            <Typography variant='body1'>Favourite quote</Typography>
                            <Typography variant='body1'>Favourite product</Typography>
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={5} className='teamMember'>
                        <img src={teamImage2} />
                        
                        <Box className='memberInfo'>
                            <Typography variant='body1'>Name</Typography>
                            <Typography variant='body1'>Position</Typography>
                            <Typography variant='body1'>Favourite quote</Typography>
                            <Typography variant='body1'>Favourite product</Typography>
                        </Box>
                        
                    </Grid>
                </Grid>
                
            </Grid>
            
       </Grid>
       </>
      ) : (
        <div style={{ width: '100%', height: '70vh', alignContent: 'center', textAlign: 'center', justifyContent: 'center', display: 'flex' }}>
          <div style={{ marginTop: '25vh' }}> <CircleLoader color="#d18726" size={150}/> </div>
        </div>
      )}
    </>


  );
}
